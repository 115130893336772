// Color scheme

$primary: #444bf8;
$success: #00c9a6;
$info: #2dd2f6;
$warning: #f1be24;
$danger: #f12459; 
$light: #e4e5e7;
$dark: #252631;

$white: #ffffff;
$black: #000000;

$body-color: #2c2c2c;
$body-bg: $white;

$border-color: #e7eaf3;

// Typography

$font-family-base: 'Poppins', sans-serif;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;

$h1-font-size: 2.5rem;
$h2-font-size: 2rem;
$h3-font-size: 1.75rem;
$h4-font-size: 1.5rem;
$h5-font-size: 1.25rem;
$h6-font-size: 1rem;

$headings-color: #000;
$text-muted: #bbbbbb;

$display1-size: 6rem;
$display2-size: 5.5rem;
$display3-size: 4.5rem;
$display4-size: 3.5rem;

$border-radius: 0.25rem;

$grid-margin: 1rem;

// Buttons

$btn-padding-x: 1rem;
$btn-padding-y: 0.75rem;
$btn-font-size: 1rem;
$btn-border-radius: $border-radius;
$btn-line-height: 1.5;


$btn-padding-x-sm: 1.125rem;
$btn-padding-y-sm: 0.625rem;
$btn-font-size-sm: 12px;
$btn-line-height: 1.33;


$btn-padding-x-lg: 1.125rem;
$btn-padding-y-lg: 1.125rem;
$btn-font-size-lg: 1.25rem;
$btn-line-height: 1.5;


// Forms

$input-height: 50px;

$input-padding-x: 1rem;
$input-padding-y: 0.75rem;

$input-border-color: #e7eaf3;
$input-border-radius: 2px;

$input-font-size: 1rem; 
$input-font-weight: $font-weight-light;
$input-line-height: 1.5;

$input-color: #bbbbbb;

// Progress Bar

$progress-height: 8px;
$progress-border-radius: $progress-height / 2;

// Range

$track-color: $light;
$thumb-color: $primary;

$thumb-radius: $border-radius;
$thumb-height: 8px;
$thumb-width: 8px;

$track-width: 100%;
$track-height: 6px;

$track-radius: 4px;

//Badges

$badge-padding-x: 12px;
$badge-padding-y: 6px;
$badge-font-size: 13px;
$badge-font-weight: 500;
$badge-border-radius: 4px;
$badge-pill-padding-x: $badge-padding-x;
// Nav Tabs

$nav-tabs-border-color: #e7eaf3;
$nav-pill-bg: $light;
$nav-pills-link-active-bg: $primary;
$nav-pills-link-active-color: $white;
$nav-pills-border-radius: $btn-border-radius;

// Alerts

$alert-bg-level: 0;

//Navbar

$navbar-dark-color: $white;
$navbar-nav-link-padding-x : 16px;